<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
  >
    <v-layout wrap>
      <div class="flex-display flex-column flex-justify-between mb-3 w-100" v-if="">
        <div class="row">
          <div class="col-md-4">
            <introduction :user="client" v-on:addReview="addReview" v-on:updateInsuranceDetail="updateInsuranceDetail"
                          ref="intro">
              <template slot="profile-image">
                <profile-picture :value="client.profile_picture"
                                 :user_id="user_id"
                                 :user-type="'client'"
                >
                </profile-picture>
              </template>
              <template slot="therapist-name">
                <client-name :first_name="client.first_name"
                             :last_name="client.last_name"
                             :user-type="'client'"
                >
                </client-name>
              </template>
              <template v-if="client" slot="client-therapy-status">
                <therapy-statuses-drop-down :client="client"></therapy-statuses-drop-down>
              </template>
              <template slot="email">
                <client-email :email="client.email_address" :user-type="'client'"></client-email>
              </template>
              <template slot="client-dob">
                <client-date-of-birth :user="client" v-on:updateDob="dobUpdate"></client-date-of-birth>
              </template>

              <template slot="client-gender">
                <client-gender :user="client" v-on:updateGender="genderUpdate"></client-gender>
              </template>

              <template slot="client-region">
                <client-region :user="client" :regions="clientRegions" v-on:updateRegion="regionUpdate"></client-region>
              </template>

                <template slot="phone">
                  <client-phone :client="client" v-on:updatePhone="phoneUpdate"></client-phone>
                </template>
              <template slot="client-risk">
                  <client-risk :client="client"></client-risk>
                </template>
            </introduction>
          </div>
          <div class="col-md-4 ">
            <session-type-permissions v-if="arePermissionsReady"
                                      :userBookingPermissions="userBookingPermissions"
                                      :userId="user_id"
                                      :sectionTitle="'Client Booking Permissions'"
            >

            </session-type-permissions>
          </div>
          <div class="col-md-4">
            <activity :activities="activities"></activity>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-4 col-md-4">
            <issues
                :user_id="user_id"
                :issues="allIssues"
                :selected-issues="clientIssues.split(',').filter(function(i){return i != ''})"
                @updateIssues="updateIssues"
            />
          </div>
          <div class="col-lg-4 col-md-4">
            <treatments
                :user_id="user_id"
                :treatments="allTreatments"
                :selected-treatments="clientTreatments.split(',').filter(function(i){return i != ''})"
                @updateTreatments="updateTreatments"
            />
          </div>
          <div class="col-lg-4 col-md-4">
            <therapists
                :user_id="user_id"
                :matched_therapists="matchedTherapists"
                :connected_therapists="connectedTherapists"
                @updateTherapists="updateTherapists"
                @updateActivites="updateActivities"
            />
          </div>
        </div>


        <div class="row">
          <div class="col-12">
            <div class="action-buttons">
              <button  class="btn btn-primary height-40 width-141 position-absolute m-t-27 m-l-80-percent" @click="redirectBookingSession">{{ addBookingButtonText }}</button>
            </div>
            <bookings v-on:refreshClientDetail="refreshBookings"
                      v-on:refresh="refreshBookings"
                      :header-title="'Bookings'"
                      :is-detail="true"
                      :user_id="user_id"
                      :user-type="'client'"
            />

          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <clinical-assessment
                :client-id="client.id"
                @openAssessmentForm="openAssessmentForm"
            ></clinical-assessment>
          </div>
          <div class="col-md-6">
            <payment
                :card="client.credit_card.card"
                :promo="client.promo_code"
                :insurance="client.insurance"
                @updateInsuranceDetail="updateInsuranceDetail"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <subscription :subscription="subscription"></subscription>
          </div>
          <div class="col-md-6">
            <document-uploader :client="client">
                </document-uploader>
          </div>
        </div>

        <!--Therapy Plan-->
        <div class="row">
          <div class="col-md-12">
            <therapy-plan
                :header-title="'Plans'"
                :is-detail="true"
                :user_id="user_id"
            ></therapy-plan>
          </div>
        </div>

        <!-- Add Booking -->
        <add-booking v-if="addBookingDialog"
                     :visible="addBookingDialog"
                     v-on:addBooking="addBooking"
                     v-on:hide="hideBookingModal"
                     :pre_selected_client="client"
                     :pre_selected_therapist="client.connected_therapists"
                     :action="'preSelectedClientBooking'"
        >
        </add-booking>

      </div>
    </v-layout>
  </v-container>
</template>

<script>
import Activity from "@/components/people/Activity";
import Bookings from "@/components/main/Bookings";
import Introduction from "@/components/people/Introduction";
import Issues from "@/components/people/Issues";
import Payment from "@/components/people/Payment";
import Therapists from "@/components/people/Therapists";
import Treatments from "@/components/people/Treatments";
import Subscription from "@/components/people/Subscription";
import DocumentUploader from "@/components/people/DocumentUploader";
import ProfilePicture from "@/components/main/ProfilePicture";
import ClientEmail from "@/components/people/TherapistEmail";
import ClientName from "@/components/people/TherapistName";
import ClientDateOfBirth from "@/components/people/ClientDateOfBirth";
import ClientAddress from "@/components/people/ClientAddress";
import therapyStatusesDropDown from "@/components/common/dropDowns/therapyStatusesDropDown";
import AddBooking from '../../components/main/AddBooking';
import SessionTypePermissions from '../../components/people/sessionTypePermissions'
import TherapyPlan from "@/components/people/TherapyPlan";
import ClientGender from "@/components/people/ClientGender";
import ClientRegion from "@/components/people/ClientRegion";
import ClientPhone from "@/components/people/ClientPhone";
import ClientRisk from "@/components/people/ClientRisk";
import ClinicalAssessment from "@/components/people/client/clinicalAssessment/ClinicalAssessment";

export default {
  components: {
    TherapyPlan,
    ClinicalAssessment,
    ClientRisk,
    ClientPhone,
    ClientRegion,
    ClientGender,
    SessionTypePermissions,
    Introduction,
    Activity,
    Issues,
    Treatments,
    Therapists,
    Bookings,
    Payment,
    Subscription,
    DocumentUploader,
    ProfilePicture,
    ClientEmail,
    ClientName,
    therapyStatusesDropDown,
    ClientAddress,
    ClientDateOfBirth,
    AddBooking,
    userBookingPermissions: [],
    arePermissionsReady: false
  },
  props: {},
  data: () => ({
    isEverythingReady: true,
    user_id: null,
    client: null,
    allIssues: [],
    clientIssues: null,
    allTreatments: [],
    clientTreatments: null,
    session_types: [],
    session_toggle: 1,
    activities: [],
    subscription: null,
    addBookingButtonText: 'Add Booking',
    addBookingDialog: false,
    connectedTherapists: [],
    matchedTherapists: [],
    clientRegions: [],
  }),
  created() {
    this.user_id = this.$route.params.id
    this.getUserPermissions()
    this.getClientProfile()
    this.getClientIssuesAndTreatments()
    this.getConnectedTherapists()
    this.getClientSessionypes()
    this.getClientActivities()
    this.getClientSubscriptions()
  },
  methods: {
    getClientIssuesAndTreatments () {
      this.$http.get(this.url + "client/issues-and-treatments", {
        params: {
          client_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.allIssues = response.data.issues;
        this.clientIssues = response.data.client_issues;
        this.allTreatments = response.data.treatments;
        this.clientTreatments = response.data.client_treatments;
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "")
      })
    },
    getConnectedTherapists () {
      this.$http.get(this.url + "client/connected-therapists", {
        params: {
          client_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.connectedTherapists = response.data
      }).catch(error => {
        this.$toastr("error" , error.response.data.message, "")
      })
    },
    getClientSessionypes () {
      this.$http.get(this.url + "client/session-types", {
        params: {
          client_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.session_types = response.data.session_types;
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "")
      })
    },
    getClientActivities () {
      this.$http.get(this.url + "client/activities", {
        params: {
          client_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.activities = response.data.activities ? response.data.activities : [];
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "")
      })
    },
    getClientSubscriptions () {
      this.$http.get(this.url + 'client/subscriptions', {
        params: {
          client_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.subscription = response.data.subscription;
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "")
      })
    },
    getClientProfile() {
      let loader = this.$loading.show()
      this.isEverythingReady = false
      this.$http.get(this.url + 'client/profile', {
        params: {
          client_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        loader.hide();
        this.client = response.data.client;
        this.clientRegions = response.data.regions;
        this.matchedTherapists = this.client.matched_therapists;
        this.session_types = response.client.session_types;
        this.session_toggle = this.client.settings.is_30_min_session_allowed.value ? 1 : 0;
        this.isEverythingReady = true;

      }).catch(error => {
        loader.hide()
        this.isEverythingReady = true
        this.$toastr('error', error.response.message, 'Error')
      })
    },
    getUserPermissions() {
      this.$http.get(this.url + "booking-permissions", {
        params: {
          user_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.userBookingPermissions = response.data.sessionTypes;
        this.arePermissionsReady = true;
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "")
      })
    },
    dobUpdate(data) {
      this.updateClientDob(data);
    },
    genderUpdate(data) {
      this.updateClientRecord(data,"gender");
    },
    regionUpdate(data) {
      this.updateClientRecord(data,"region");
    },
    phoneUpdate(data) {
      this.updateClientRecord(data,"phone");
    },
    addressUpdate(data) {
      this.addInsuranceDetails(data, "address")
    },
    addInsuranceDetails(data, ref) {
      let formData = {};
      if (ref === "dob") {
        formData = {
          client_id: this.client.id,
          date_of_birth: data,
          device_type: "web"
        };
      } else {
        formData = {
          client_id: this.client.id,
          house_address: data.house_address,
          city: data.city,
          postal_code: data.postal_code,
          device_type: "web"
        };
      }
      let loader = this.$loading.show();
      this.$http.put(this.url + "update_insurance_details", formData).then((response) => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
        this.$emit("updateInsuranceDetail", response.data.insurance);
      }).catch((error) => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },

    updateClientDob(data) {
      let formData = {};
        formData = {
          client_id: this.client.id,
          date_of_birth: data,
          device_type: "web"
        };
      let loader = this.$loading.show();
      this.$http.put(this.url + "client/dob", formData).then((response) => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
      }).catch((error) => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },

    updateClientRecord(data,colName) {
      let formData = {};
      if (colName === 'gender') {
        formData = {
          client_id: this.client.id,
          gender: data,
          device_type: "web"
        };
      } else if (colName === 'region') {
        formData = {
          client_id: this.client.id,
          region: data,
          device_type: "web"
        };
      } else if (colName === 'phone') {
        formData = {
          client_id: this.client.id,
          phone: data,
          device_type: "web"
        };
      }
      let loader = this.$loading.show();
      this.$http.put(this.url + "client/personal-details", formData).then((response) => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
      }).catch((error) => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },
    updateIssues(issues) {
      this.clientIssues = issues
    },
    updateTreatments(treatments) {
      this.clientTreatments = treatments
    },
    updateTherapists(therapists) {
      this.connectedTherapists = therapists
    },
    updateActivities(activities) {
      this.activities = activities
    },
    addReview(initialAssessment) {
      this.client.initial_assessment = initialAssessment
    },
    updateInsuranceDetail(insurance) {
      this.client.insurance = insurance;
    },
    openAssessmentForm() {
      this.$refs.intro.showAssessmentModal()
    },
    refreshBookings() {
      this.client_id = this.$route.params.id
      this.getClientProfile()
    },
    showBookingModal() {
      this.addBookingDialog = true;
    },
    hideBookingModal() {
      this.addBookingDialog = false;
    },
    addBooking(booking_data) {
      let loader = this.$loading.show();
      this.$http.put(this.url+'booking', booking_data).then(response => {
        loader.hide();
        this.hideBookingModal();
        this.$events.fire('vuetable:reload');
        this.$toastr('success',response.data.message,'');
        this.trackBooking(response.data.booking_id);
        this.$router.go();
      }).catch(error => {
        loader.hide();
        this.$toastr('add', {
          msg: error.response.data.message, // Message
          timeout: 13000, // Timeout in ms
          type: 'error',
        });
      });
    },
    trackBooking(bookedSessionID) {
      this.$http.get(this.url + 'track_booking_event', {
        params: {
          booking_id: bookedSessionID
        }
      }).then(response => {

        let bookingData = response.data.data;

        // E-commerce event
        window.dataLayer = window.dataLayer || [];

        let products = [{
          'name': bookingData.product_name,
          'id': bookingData.product_sku,
          'price': bookingData.revenue,
          'brand': bookingData.affiliation,
          'category': bookingData.product_category,
          'variant': bookingData.product_variant,
          'quantity': 1,
          'coupon': ''
        }];

        window.dataLayer.push({
          'event': 'eec_purchase',
          'ecommerce': {
            'purchase': {
              'actionField': {
                'id': bookingData.transaction_id.toString(),                         // Transaction ID. Required for purchases and refunds.
                'affiliation': bookingData.affiliation,
                'revenue': bookingData.revenue,                     // Total transaction value (incl. tax and shipping)
                'tax': 0,
                'shipping': 0,
                'coupon': '',
                'products': products
              },
            }
          }
        });

      }).catch(error => {
        console.log(error);
      });
    },
    redirectBookingSession() {
      this.$events.fire('getPreSelectedClient',this.client);
      window.location.href = "/add_booking?client_id=" + this.client.id;
    },
  }
}
</script>
<style scoped>
.toggle-switch {
  display: flex;
  justify-content: space-between;
}

.booking-permissions-container {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px 5px 5px 5px;
}

.m-l-80-percent {
  margin-left: 80% !important;
}

</style>
